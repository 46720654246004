
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

/** Component for various order windows/menus **/
@Options({
	name: 'OrderWindow',
	components: {},
})
export default class OrderWindow extends Vue {
	@Prop({ default: null }) windowId!: string | null;
}
