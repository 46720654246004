
import { Options, Vue } from 'vue-class-component';
import ExpSectionBase from './exp-section-base.component.vue';
import { InjectReactive, Watch } from 'vue-property-decorator';
import MonthAvailability from './date/month-availability.component.vue';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import DateShortcuts from './date/date-shortcuts.component.vue';
import { format } from 'date-fns';

@Options({
	name: 'DateSection',
	components: { ExpSectionBase, DateShortcuts, MonthAvailability },
	emits: ['onFocus', 'onNext'],
})
export default class DateSection extends Vue {
	@InjectReactive() activeSection!: string;
	@InjectReactive() activity!: OrderActivity;

	showCalendar = false;

	get activeLabel() {
		return !!(this.selectedDate || this.summary || this.isActiveSection);
	}

	get hasRates() {
		return !!this.activity.rateCount;
	}

	get isActiveSection() {
		return this.activeSection == 'dates';
	}

	get selectedDate() {
		return this.activity.selectedDate;
	}

	get showShortcuts() {
		if (this.isActiveSection || !this.selectedDate) return true;
		return false;
	}

	get summary() {
		return this.activity.selectedDate ? format(this.activity.selectedDate, 'EEE, LLL dd') : null;
	}

	selectDate(d: Date) {
		console.log('Calling GBT from selectDate');
		this.$emit('onFocus');
		this.showCalendar = false;
		this.activity.selectDate(d);
		this.activity.getBookTimes();
		this.$emit('onNext', this.hasRates ? 'times' : 'rates');
	}

	toggleCalendar() {
		this.$emit('onFocus');
		this.showCalendar = !this.showCalendar;
	}
}
