
import { Options, Vue } from 'vue-class-component';
import { add, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, isThisMonth, getDay, isFuture, isSameDay } from 'date-fns';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays/index';
import { checkoutModule } from '@/modules/checkout/checkout.vuex-module';
import { Prop } from 'vue-property-decorator';
import isToday from 'date-fns/isToday';
import { DayOfTheMonth } from '@/common/models/DayOfTheMonth.model';

@Options({
	name: 'MonthAvailability',
	components: {},
	emits: ['onSelect'],
})
export default class MonthAvailability extends Vue {
	@Prop({ default: new Date() }) selectedDate!: Date;

	date!: Date;
	monthName!: string;

	calendar: {
		date: Date;
		number: string;
		inMonth: boolean;
		soldOut: boolean;
		available: boolean;
		closed: boolean;
		selected: boolean;
		disabled: boolean;
		addDelay: boolean;
	}[] = [];

	weekdays: string[] = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

	created() {
		this.date = this.selectedDate ? this.selectedDate : new Date();

		this.setMonth(0);
	}

	createCalendar(d: Date) {
		this.calendar = [];
		const monthStart = startOfMonth(d);
		const monthEnd = endOfMonth(d);
		const calStart = startOfWeek(monthStart, { weekStartsOn: 0 });
		const calEnd = endOfWeek(monthEnd, { weekStartsOn: 0 });

		for (let i = 0; i <= differenceInCalendarDays(calEnd, calStart); i++) {
			const day = add(calStart, { days: i });
			this.calendar.push({
				number: format(day, 'dd'),
				inMonth: isThisMonth(day) || isFuture(day),
				soldOut: false,
				available: false,
				closed: false,
				date: day,
				disabled: !isToday(day) && !isFuture(day),
				selected: this.selectedDate && isSameDay(day, this.selectedDate),
				addDelay: false,
			});
		}

		this.mapAvailability(monthStart);
	}

	setMonth(dir: number) {
		this.date = add(this.date, { months: dir });
		this.monthName = format(this.date, 'MMMM');
		this.createCalendar(this.date);
	}

	async mapAvailability(d: Date) {
		// maps availability properties to calendar days
		const setData = (d: Date, availability: DayOfTheMonth[]) => {
			const availStartDay = getDay(d); // int of day of week
			for (let i = 0; i <= availability.length; i++) {
				const idx = i + availStartDay;

				if (availability[i]) {
					this.calendar[idx].soldOut = availability[i].SoldOut;
					this.calendar[idx].available = availability[i].Available;
					this.calendar[idx].closed = availability[i].Closed;
					this.calendar[idx].disabled = this.calendar[idx].disabled || availability[i].Closed || availability[i].SoldOut;
					this.calendar[idx].addDelay = availability[i].Available || availability[i].Closed || availability[i].SoldOut;
				}
			}
		};

		// check if month availability cached
		if (checkoutModule.monthAvailability.has(format(d, 'MMMM-dd-yyyy'))) {
			setData(d, checkoutModule.monthAvailability.get(format(d, 'MMMM-dd-yyyy'))!);
		} else {
			checkoutModule.getMonthAvailability(d).then((res) => {
				if (res) setData(d, checkoutModule.monthAvailability.get(format(d, 'MMMM-dd-yyyy'))!);
			});
		}
	}
}
