
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'NumberTicker',
	components: {},
	emits: ['onChange', 'onEnter'],
})
export default class NumberTicker extends Vue {
	@Prop({ default: 0 }) value!: number;
	@Prop({ default: 0 }) min!: number;
	@Prop({ default: null }) max!: number;
	@Prop({ default: false }) disableAdd!: boolean;
	@Prop({ default: false }) disableSub!: boolean;
	@Prop({ default: false }) ofMax!: boolean;

	get inputValue() {
		return this.value != 0 ? this.value : null;
	}

	set inputValue(v: number | null) {
		if (v == null || isNaN(v)) return;
		this.onUpdate(v);
	}

	onUpdate(v: number) {
		if (typeof v !== 'number') return;
		if (v < this.min) return;
		if (this.max && this.max < v) return;
		if (this.disableAdd && v >= this.value) return;
		this.$emit('onChange', v);
		this.$forceUpdate();
	}
}
