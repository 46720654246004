
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'ToggleSwitch',
})
export default class ToggleSwitch extends Vue {
	@Prop() checked!: boolean;
}
