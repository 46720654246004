
import { Options, Vue } from 'vue-class-component';
import { OnClickOutside } from '@vueuse/components';
import { Prop } from 'vue-property-decorator';
import OrderWindow from './order-window.component.vue';
import CloseButton from '@/common/components/form-ui/close-button.component.vue';
import { clientModule } from '@/modules/client/client.vuex-module';

@Options({
	name: 'OrderMenu',
	components: { OrderWindow, OnClickOutside, CloseButton },
	emits: ['onClose'],
})
export default class OrderMenu extends Vue {
	@Prop({ default: null }) menuId!: string | null;
	@Prop({ default: true }) mobileBackBtn!: boolean;

	get mobileView() {
		return !clientModule.desktopView;
	}
}
