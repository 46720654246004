
import { Time } from '@/common/models/Time.model';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { OnClickOutside } from '@vueuse/components';
import NumberTicker from '@/modules/order/shop/components/common/number-ticker.component.vue';
import { EntityHierarchyRate } from '@/common/models/EntityHierarchyRate.model';

@Options({
	name: 'TimeSlot',
	components: { OnClickOutside, NumberTicker },
	emits: ['onTimeSelect'],
})
export default class TimeSlot extends Vue {
	@Prop() time!: Time;
	@Prop({ default: false }) isSelectedTime!: boolean;
	@Prop({ default: false }) isLargeGroup!: boolean;

	ratesClone!: EntityHierarchyRate[];

	showEditRatesModal = false;
	showLargeGroupModal = false;

	get timeStr() {
		return this.time.DisplayName.split(' '); // split meridian
	}

	get timeStatus() {
		if (this.totalAvailable == 0) return 'Full';
		return `${this.totalAvailable} available`;
	}

	get timePrice() {
		return this.isLargeGroup ? this.time.Rates.reduce((p, c) => (p += c.Participants * c.Price), 0) : this.time.SubTotal;
	}

	get assignedToSlot() {
		return this.isLargeGroup ? this.time.Rates.reduce((p, c) => (p += c.Participants), 0) : 0;
	}

	get totalAvailable() {
		return this.time.TotalAvailable - this.assignedToSlot;
	}

	get largeGroupAssignment() {
		return this.time.Rates.map((r) => (r.Participants ? `, ${r.Participants} x ${r.Name}` : ''))
			.join('')
			.replace(', ', '');
	}
}
