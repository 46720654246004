
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'ExpSectionBase',
})
export default class ExpSectionBase extends Vue {
	@Prop({ default: '' }) label!: string;
	@Prop({ default: false }) canOpen!: boolean;
	@Prop({ default: false }) cardOpen!: boolean;
	@Prop({ default: false }) inactive!: boolean;
}
