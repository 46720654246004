import { clientModule } from '@/modules/client/client.vuex-module';
import { ClientEmployee } from '@/common/models/ClientEmployee.model';
import { ClientLocation } from '@/common/models/ClientLocation.model';
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';

@Options({
	name: 'Client Mixin',
	computed: {
		...mapGetters('clientModule', {
			desktopView: 'desktopView',
			user: 'user',
			availableLocations: 'availableLocations',
			locationActivityKeys: 'entityHierarchyKeys',
			location: 'location',
			clientKey: 'clientKey',
			clientLocationKey: 'clientLocationKey',
			clientEmployeeKey: 'clientEmployeeKey',
			clientPassword: 'clientPassword',
			userPlatform: 'userPlatform',
			locationActivities: 'locationActivities',
			clientLoading: 'isLoading',
			clientName: 'clientName',
			locationTimezoneOffset: 'locationTimezoneOffset',
		}),
	},
})
export class ClientMixin extends Vue {
	/* GETTERS */
	desktopView!: boolean;
	user!: ClientEmployee | null;
	availableLocations!: ClientLocation[];
	location!: ClientLocation;
	clientKey!: number;
	locationActivityKeys!: number[];
	clientLocationKey!: number;
	locationActivities!: EntityHierarchy[];
	clientPassword!: string;
	clientName!: string;
	clientLoading!: boolean;
	locationTimezoneOffset!: number;

	/* ACTIONS */
	detectUserPlatform(): void {
		clientModule.detectUserPlatform();
	}

	async logout(): Promise<boolean> {
		return clientModule.logout();
	}

	getInternalActivityName(key: number): string {
		return this.locationActivities.find((a) => a.EntityHierarchyKey == key)?.InternalName ?? '';
	}

	async initializeClientModule(): Promise<void> {
		clientModule.initializeClient();
	}

	async updateClientLocation(key: number): Promise<boolean> {
		return clientModule.updateClientLocation(key);
	}
}
